/*
 * Sidebar - Modern Minimalist Style
 */
 .sidebar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    background-color: #1f2d3d;  /* Dark, modern background */
    color: #ecf0f1;  /* Light text color for readability */
    transition: max-width 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 240px;  /* Slightly narrower for a minimalist look */
    }

    .nav {
        white-space: nowrap;
    }

    .nav-item {
        margin-bottom: 0.4rem;
        border: none;

        &.active > .nav-link {
            color: #fff;
            background-color: #2c3e50;  /* A subtle highlight for active links */
            border-left: 4px solid #1abc9c;  /* Green accent for active state */
        }

        .nav-link {
            color: #bdc3c7;
            padding: 0.75rem 1rem;
            font-size: 0.95rem;  /* Slightly smaller font for a modern look */
            border-radius: 6px;
            transition: background-color 0.3s, color 0.3s;

            &:hover {
                color: #fff;
                background-color: #34495e;
            }

            &.active {
                color: #ecf0f1;
            }

            .sidebar-icon {
                margin-right: 0.5rem;
                color: #95a5a6;  /* Muted icon color */
                transition: color 0.3s;
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.4);  /* Light blue focus ring */
            }
        }
    }

    .multi-level .nav-link {
        padding-left: 2rem;
    }

    .sidebar-text,
    .link-arrow,
    .badge,
    .notification-count {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    &.contracted {
        .sidebar-text,
        .link-arrow,
        .badge {
            opacity: 0;
        }

        .notification-count {
            opacity: 1;
            position: absolute;
            top: 0;
            left: 35px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 0.7rem;
            padding: 0.2rem;
        }

        .nav-item {
            width: 50px;
            text-align: center;
            margin-bottom: 1rem;

            &.active > .nav-link {
                background-color: #34495e;
                border-left: none;
            }

            .nav-link {
                padding: 0.75rem;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #34495e;
                }
            }
        }
    }

    .accordion-button {
        &::after {
            content: '\f054';
            font-family: $font-awesome-5;
            font-size: 0.75rem;
            font-weight: 900;
            height: 1.25rem;
            width: 0.5rem;
        }

        &:not(.collapsed) {
            background-color: #2c3e50;

            &::after {
                transform: rotate(90deg);
                transition: transform 0.2s ease-in-out;
            }
        }
    }

    .accordion-body {
        padding: 0.5rem 0;
    }
}

.sidebar-inner {
    overflow-y: hidden;
    padding: 1rem 0;
    .user-card {
        border-bottom: 0.0625rem solid #2c3e50;
        margin-bottom: 1rem;
    }
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #bdc3c7;
    margin-bottom: 1rem;
}

.content {
    padding: 1rem;
    transition: margin-left 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
        margin-left: 240px;
    }
}

.sidebar-toggle {
    font-size: 1.2rem;
    color: #ecf0f1;
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: #1abc9c;  /* Green color on hover */
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.sidebar-transition-enter {
    opacity: 0;
}

.sidebar-transition-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
}

.sidebar-transition-exit {
    opacity: 1;
}

.sidebar-transition-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
