/**
 * = Modern Minimalist Sidebars
 */

/* Hide sidebar on smaller screens */
#doc-index:not(.collapse.show), .doc-sidebar {
    display: none;
}

/* Show sidebar on larger screens */
@include media-breakpoint-up(lg) {
    #doc-index {
        display: block;
    }

    .doc-sidebar {
        display: block;
        height: calc(100vh - 2rem);
        overflow-y: auto;
        position: sticky;
        top: 2rem;
        background-color: #2c3e50; /* Modern dark background */
        padding: 1rem;
        border-radius: 8px; /* Smooth rounded corners */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        
        /* Scrollbar styling */
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #34495e;
            border-radius: 3px;
        }

        .nav-link {
            font-size: $font-size-sm;
            color: #ecf0f1; /* Light text color */
            padding: 0.75rem 1rem;
            border-radius: 6px;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: #34495e; /* Hover background */
                color: #ffffff; /* Hover text color */
            }

            &.active {
                background-color: #1abc9c; /* Active link background */
                color: #ffffff; /* Active link text color */
            }

            .icon {
                margin-right: 8px;
                transition: transform 0.3s ease;
            }

            &[data-toggle="collapse"] > .icon {
                transform: rotateZ(-90deg);
            }

            .nav-item {
                font-size: $font-size-sm;
                color: inherit; /* Inherit color from parent */
            }
        }

        .nav-item {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/* Upgrade to Pro button styling */
.upgrade-to-pro {
    position: fixed;
    left: 15px;
    bottom: 15px;
    width: 230px;
    z-index: 999;
    background-color: #e74c3c; /* Button background */
    color: #ffffff; /* Button text color */
    padding: 0.75rem;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow for emphasis */
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c0392b; /* Darker background on hover */
        color: #ffffff; /* Ensure text color remains white */
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .doc-sidebar {
        padding: 0.5rem;
        border-radius: 0;
        height: auto;
    }

    .upgrade-to-pro {
        left: 10px;
        bottom: 10px;
        width: 200px;
        padding: 0.5rem;
    }
}
