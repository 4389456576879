body {
    overflow-x: hidden;
    font-family: 'Inter', sans-serif; /* Clean, modern font */
}

/* Remove number input arrows */
input[type=number] {
    -moz-appearance: textfield; 
    -webkit-appearance: none;
    appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Auth Page Styling */
.system_auth_content {
    background: url('./Imgs/sign-in-illustration.svg') no-repeat;
    background-size: 50%; /* Ajusta el tamaño de la imagen */
    background-position: calc(80% - 70px) center; /* Mueve la imagen un poco más a la derecha */
    min-height: 100vh;
    display: flex;
    justify-content: flex-start; /* Alinea los elementos a la izquierda */
    align-items: center;
    padding: 2rem;
}








.system_auth_card_content {
    background-color: #1a1f2e;
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 8px;
}

.system_auth_card_title svg {
    color: #fff;
    transform: scale(1.5);
    margin-bottom: 1rem;
}

.system_auth_card_title h3 {
    color: #f3795f;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.system_auth_card_form_input_label {
    color: #9aa0b3;
    margin-bottom: 0.5rem;
}

.system_auth_card_content a {
    color: #9aa0b3 !important;
    transition: color 0.3s ease;
}

.system_auth_card_content a:hover {
    color: #ffffff !important;
}
/* Estilos generales para los botones */
.system_auth_btn_login, 
.system_auth_btn_register, 
.system_auth_btn_back {
    box-shadow: none;
    border-radius: 25px; /* Bordes redondeados */
    padding: 0.5rem 0.5rem; /* Tamaño del padding */
    font-size: 1rem; /* Tamaño de fuente */
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transición para el color y efecto de escala */
    width: 100%; /* Asegura que los botones tengan el mismo ancho */
    margin-top: 15px; /* Espaciado entre botones */
}

/* Botón de inicio de sesión */
/* Estilos generales para los botones */
.system_auth_btn_login, 
.system_auth_btn_register, 
.system_auth_btn_back {
    box-shadow: none;
    border-radius: 25px; /* Bordes redondeados */
    padding: 0.35rem 1rem; /* Reducción del tamaño del padding */
    font-size: 0.9rem; /* Tamaño de fuente más pequeño */
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transición para el color y efecto de escala */
    width: 100%; /* Asegura que los botones tengan el mismo ancho */
    margin-top: 10px; /* Espaciado entre botones */
}

/* Botón de inicio de sesión */
.system_auth_btn_login {
    background-color: #0cc4f1; /* Color turquesa */
    color: #fff;
    padding: 0.35rem;
}

/* Efecto hover para el botón de inicio de sesión */
.system_auth_btn_login:hover, 
.system_auth_btn_login:focus, 
.system_auth_btn_login:active {
    background-color: #5bb6db !important; /* Color más oscuro en hover */
    transform: scale(1.05); /* Efecto de ampliación en hover */
}

/* Botón de registro */
.system_auth_btn_register {
    background-color: transparent;
    color: #70d5ee; /* Color turquesa */
    border: 1px solid #70d5ee; /* Borde turquesa */
}

/* Efecto hover para el botón de registro */
.system_auth_btn_register:hover, 
.system_auth_btn_register:focus, 
.system_auth_btn_register:active {
    background-color: #70d5ee !important; /* Fondo turquesa en hover */
    color: #fff !important; /* Texto blanco */
    transform: scale(1.05); /* Efecto de ampliación en hover */
}

/* Botón de volver a iniciar sesión */
.system_auth_btn_back {
    background-color: transparent;
    color: #70d5ee; /* Color turquesa */
    border: 1px solid #70d5ee; /* Borde turquesa */
}

/* Efecto hover para el botón de volver */
.system_auth_btn_back:hover, 
.system_auth_btn_back:focus, 
.system_auth_btn_back:active {
    background-color: #70d5ee !important; /* Fondo turquesa en hover */
    color: #fff !important; /* Texto blanco */
    transform: scale(1.05); /* Efecto de ampliación en hover */
}


/* Breadcrumb */
.breadcrumb-item.active {
    color: #1a1f2e;
    font-weight: bold;
}

/* Sidebar */
.app_sidebar_navigation {
    background-color: #201a2b !important;
}

.sidebar .nav-item.active > .nav-link {
    color: #c1c8dc;
    font-weight: bold;
    background-color: #f26542;
    border-radius: 4px;
}

.sidebar .nav-item .nav-link:hover {
    color: #878fa8;
    background-color: rgba(242, 101, 66, 0.9);
    border-radius: 4px;
}

/* Buttons */
.panel_admin_btn_add_data,
.panel_admin_btn_success,
.panel_admin_btn_primary,
.panel_admin_btn_secondary,
.panel_admin_btn_danger {
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.panel_admin_btn_add_data {
    background-color: #77ff33;
    color: #1a1f2e;
    border: none;
}

.panel_admin_btn_add_data:hover,
.panel_admin_btn_add_data:focus,
.panel_admin_btn_add_data:active {
    background-color: #bdff72 !important;
    box-shadow: 0px 0px 10px rgba(56, 184, 50, 0.4);
}

.panel_admin_btn_success {
    background-color: #38b832;
    color: #fff;
    border: none;
}

.panel_admin_btn_success:hover,
.panel_admin_btn_success:focus,
.panel_admin_btn_success:active {
    background-color: #4bc145 !important;
    box-shadow: 0px 0px 10px rgba(56, 184, 50, 0.4);
}

.panel_admin_btn_primary {
    background-color: #347eb4;
    color: #fff;
    border: none;
}

.panel_admin_btn_primary:hover,
.panel_admin_btn_primary:focus,
.panel_admin_btn_primary:active {
    background-color: #4492c9 !important;
    box-shadow: 0px 0px 10px rgba(56, 184, 50, 0.4);
}

.panel_admin_btn_secondary {
    background-color: #f26d00;
    color: #fff;
    border: none;
}

.panel_admin_btn_secondary:hover,
.panel_admin_btn_secondary:focus,
.panel_admin_btn_secondary:active {
    background-color: #fc8d33 !important;
    box-shadow: 0px 0px 10px rgba(56, 184, 50, 0.4);
}

.panel_admin_btn_danger {
    background-color: #c40514;
    color: #fff;
    border: none;
}

.panel_admin_btn_danger:hover,
.panel_admin_btn_danger:focus,
.panel_admin_btn_danger:active {
    background-color: #f93444 !important;
    box-shadow: 0px 0px 10px rgba(56, 184, 50, 0.4);
}

/* Data Export Button */
.app_btn_export_data {
    background-color: #1e3648;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.app_btn_export_data:hover,
.app_btn_export_data:focus,
.app_btn_export_data:active {
    background-color: #2e4e68 !important;
}

/* Admin Panel */
.panel_admin_card_consortiums {
    background-color: #ffffff;
    color: #131621;
    border: 1px solid #dcdcdc;
    border-top: 4px solid #565429;
    border-radius: 4px;
    padding: 1rem;
    transition: box-shadow 0.3s ease;
}

.panel_card_menu_shadow:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/* Forms */
.panel_admin_form_crud {
    background-color: #fafafa;
    border-radius: 4px;
    padding: 1.5rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.panel_admin_form_input {
    color: #263b40;
    border: 1px solid rgba(38, 43, 64, 0.3);
    background-color: rgba(38, 43, 64, 0.05);
    padding: 0.75rem;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.panel_admin_form_input.form-control:focus,
.panel_admin_form_input.form-select:focus {
    border-color: #566190;
    box-shadow: none;
}

/* Table */
.panel_admin_table_list_content.table thead {
    background-color: #131621;
    color: #fff;
    text-align: center;
    border: none;
}

.panel_admin_table_list_content tbody tr {
    color: #131621;
    background-color: rgba(38, 43, 64, 0.05);
    border-bottom: 2px solid black;
}

.table-responsive::-webkit-scrollbar {
    height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
    background: #131621;
}

.table-responsive::-webkit-scrollbar-thumb {
    background: #838383;
    border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
    background: #535353;
}

/* Board */
.board_reclamos {
    background-color: #272b41 !important;
    padding: 1rem;
    border-radius: 8px;
}

.board_reclamos_card_content {
    background-color: #fff;
    color: #131621;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin-bottom: 1rem;
}

.board_reclamos_card_content .row {
    align-items: center;
    justify-content: space-between;
}

.board_reclamos_card_content .card-text {
    color: #131621;
    text-align: left;
}

.board_reclamos .smooth-dnd-container .react-trello-lane {
    min-width: 320px !important;
}

