/* src/variables.css */
:root {
    --card-bg: #ffffff;
    --card-text: #333333;
    --card-hover-shadow: rgba(0, 0, 0, 0.2);
    --background-color: #f0f2f5;
    --text-color: #333333;
    --primary-color: #007bff;
    --primary-color-dark: #1a73e8;
    --primary-color-light: #007bff;
}

[data-theme="dark"] {
    --card-bg: #1e1e1e;
    --card-text: #f0f0f0;
    --card-hover-shadow: rgba(255, 255, 255, 0.2);
    --background-color: #121212;
    --text-color: #f0f0f0;
    --primary-color: var(--primary-color-dark)
}

[data-theme="light"] {
    --primary-color: var(--primary-color-light);
}


/* Aplicar el color de fondo global */
body {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Estilos adicionales para los componentes */
.card {
    background-color: var(--card-bg);
    color: var(--card-text);
    box-shadow: 0 4px 6px var(--card-hover-shadow);
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 6px 8px var(--card-hover-shadow);
}

.button-primary {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
}

.button-primary:hover {
    background-color: #0069d9; /* Opcional: Puedes usar una función CSS para oscurecer el color */
}

/* Estilos para la barra lateral según el tema */
.sidebar-light {
    background-color: var(--card-bg);
    color: var(--text-color);
}

.sidebar-dark {
    background-color: var(--card-bg);
    color: var(--text-color);
}

/* Otros estilos según tus necesidades */
